import React from "react";
import "./Recuadro.css";

export const Recuadro = (props) => {
  return (
    <>
      <div className="recuadro-cursos">
        <img src={props.source} alt={props.imagenTexto} />
        <a href={props.direccion}>
          <h3>{props.texto}</h3>
        </a>
      </div>
    </>
  );
};
